import styled from "@emotion/styled"

// Posts and Inspirations

/*
 * Article
 * Provides a fixed background bar for headers logo, above content but below hero image
 */
export const Article = styled.article`
  font-size: 1.25rem;
  width: 100%;

  &:after {
    background-color: #d4291a;
    content: "";
    height: 55px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 15;
  }
`

/*
 * Main Title
 */
export const Title = styled.h1`
  color: #e1e1d4;
  font-size: 4.5rem;
  font-weight: 400;
  left: 0;
  margin: 0;
  padding: 0 25px 125px;
  position: absolute;
  text-align: center;
  top: 0;
  transform: translateY(-100%);
  z-index: 99;

  @media (min-width: 991px) {
    color: inherit;
    font-size: 6rem;
    max-width: 80%;
    padding: 0;
    position: relative;
    text-align: left;
    transform: none;
    z-index: inherit;
  }
`

/*
 * Subtitle
 */
export const Subtitle = styled.h2`
  color: ${(props) => props.color || "#143D6E"};
  font-family: "halyard-display", serif;
  font-weight: 500;
  font-size: 1.875rem;
  letter-spacing: 0.01em;
  text-transform: none;
`

/*
 * Kicker
 */
export const Kicker = styled.h3`
  color: ${(props) => props.color || "#143D6E"};
  font-family: "halyard-display", serif;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0;
  text-transform: none;
`

/*
 * Text block
 */
export const TextBlock = styled.div`
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
`

/*
 * Recipe blocks for columns and sorting on mobile/desktop
 */
export const RecipeBlock = styled.div`
  display: ${(props) => (props.hide === "xs" ? "none" : "block")};
  max-width: 100%;
  padding: 24px;
  position: relative;

  @media (min-width: 991px) {
    display: ${(props) => (props.hide === "md" ? "none" : "block")};
    flex: ${(props) => (props.columns === 2 ? "0 0 100%" : "0 0 50%")};
    margin-left: ${(props) => (props.float === "right" ? "50%" : "")};
    padding: 24px 12px;
    order: ${(props) => props.order || 0};
  }

  &:not(:first-of-type):after {
    background-image: radial-gradient(
      circle closest-side,
      #143d6e calc(100% - 0.5px),
      transparent 100%
    );
    background-repeat: repeat-x;
    background-size: 12px 4px;
    content: "";
    display: block;
    height: 4px;
    left: 12px;
    opacity: 0.85;
    position: absolute;
    top: 0;
    transform: translateY(-50%);
    width: calc(100% - 24px);
    z-index: 9;
  }
`
